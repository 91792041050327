import { authApi, IAuthApi } from '~/categories/admin/apis/adminApiClient/authApi';
import { graphqlAPI, IGraphqlAPI } from '~/categories/admin/apis/adminApiClient/graphqlAPI';
import { IProposalApi, proposalApi } from '~/categories/admin/apis/adminApiClient/proposalApi';

export interface IAdminApiClient extends IProposalApi, IAuthApi, IGraphqlAPI {}

export const adminApiClient: IAdminApiClient = {
  ...proposalApi,
  ...authApi,
  ...graphqlAPI,
};
