import { FetcherParams } from 'graphiql';
import { aspidaClient } from '~/categories/admin/apis/utils/aspidaClient';

export interface IGraphqlAPI {
  doGraphql(params: FetcherParams): Promise<{}>;
}

export const graphqlAPI: IGraphqlAPI = {
  doGraphql: async (params) => {
    return await aspidaClient.api.v1.graphql.$post({ body: params });
  },
};
