export const adminPathBuilder = () => ({
  login: () => `/`,
  proposal: () => ({
    list: () => `/proposals`,
    detail: (id: string) => `/proposals/${id}`,
    request: (proposalIds: number[]) => `/proposals/request?proposal_ids=${proposalIds.join(',')}`,
  }),
  knowledge: () => `/knowledge`,
  graphiql: () => `/graphiql`,
  adminUser: () => ({
    list: () => `/admin_users`,
  }),
  bulkUpdateServiceFeeOperation: () => ({
    list: () => `/bulk_update_service_fee_operations`,
    detail: (id: string) => `/bulk_update_service_fee_operations/${id}`,
  }),
});
