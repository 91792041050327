/* eslint-disable */
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
import { Methods as Methods0 } from './api/v1/graphql'
import { Methods as Methods1 } from './api/v1/proposal_progresses/_proposal_progress_id@number'
import { Methods as Methods2 } from './api/v1/proposals'
import { Methods as Methods3 } from './api/v1/proposals/_proposal_id@number'
import { Methods as Methods4 } from './api/v1/proposals/_proposal_id@number/request'
import { Methods as Methods5 } from './api/v1/sign_in/consume'
import { Methods as Methods6 } from './api/v1/sign_out'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/admin/api/v1/graphql'
  const PATH1 = '/admin/api/v1/proposal_progresses'
  const PATH2 = '/admin/api/v1/proposals'
  const PATH3 = '/request'
  const PATH4 = '/admin/api/v1/sign_in/consume'
  const PATH5 = '/admin/api/v1/sign_out'
  const GET = 'GET'
  const POST = 'POST'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        graphql: {
          post: (option: { body: Methods0['post']['reqBody'], headers?: Methods0['post']['reqHeaders'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
          $post: (option: { body: Methods0['post']['reqBody'], headers?: Methods0['post']['reqHeaders'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`
        },
        proposal_progresses: {
          _proposal_progress_id: (val0: number) => {
            const prefix0 = `${PATH1}/${val0}`

            return {
              patch: (option: { body: Methods1['patch']['reqBody'], headers?: Methods1['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods1['patch']['status']>(prefix, prefix0, PATCH, option).send(),
              $patch: (option: { body: Methods1['patch']['reqBody'], headers?: Methods1['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods1['patch']['status']>(prefix, prefix0, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix0}`
            }
          }
        },
        proposals: {
          _proposal_id: (val1: number) => {
            const prefix1 = `${PATH2}/${val1}`

            return {
              request: {
                patch: (option: { body: Methods4['patch']['reqBody'], headers?: Methods4['patch']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods4['patch']['status']>(prefix, `${prefix1}${PATH3}`, PATCH, option).send(),
                $patch: (option: { body: Methods4['patch']['reqBody'], headers?: Methods4['patch']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods4['patch']['status']>(prefix, `${prefix1}${PATH3}`, PATCH, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH3}`
              },
              get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, prefix1, GET, option).json(),
              $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}`
            }
          },
          get: (option: { query: Methods2['get']['query'], headers?: Methods2['get']['reqHeaders'], config?: T }) =>
            fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
          $get: (option: { query: Methods2['get']['query'], headers?: Methods2['get']['reqHeaders'], config?: T }) =>
            fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods2['get']['query'] }) =>
            `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        sign_in: {
          consume: {
            post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
              fetch(prefix, PATH4, POST, option).send(),
            $post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
              fetch(prefix, PATH4, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH4}`
          },
        },
        sign_out: {
          delete: (option?: { headers?: Methods6['delete']['reqHeaders'], config?: T }) =>
            fetch<void, Methods6['delete']['resHeaders'], Methods6['delete']['status']>(prefix, PATH5, DELETE, option).send(),
          $delete: (option?: { headers?: Methods6['delete']['reqHeaders'], config?: T }) =>
            fetch<void, Methods6['delete']['resHeaders'], Methods6['delete']['status']>(prefix, PATH5, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH5}`
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
